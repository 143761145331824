import { useState, useEffect } from "react";
import { Frame, Loading, Header } from "../components";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import moment from "moment";
import { Button } from "react-bootstrap";

const Prorrogacao = () => {
  const [dados, setDados] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const handleAccept = async () => {
    setLoading(true);

    await setDoc(
      doc(db, "furg2024", uid),
      { prorrogado: moment().format() },
      { merge: true }
    );

    alert("Contrato prorrogado com sucesso!");

    navigate("../home");
  };

  const handleCancel = async () => {
    setLoading(true);

    await setDoc(doc(db, "furg2024", uid), { status: "c" }, { merge: true });

    alert("Contrato cancelado com sucesso!");

    navigate("../home");
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "furg2024", uid));

    const data = qry.data();

    if (data?.prorrogado) {
      navigate("../home");
    } else {
      setDados(data);
      setLoading(false);
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      {!loading && (
        <Frame
          body={
            <div className="p-2">
              <h5>PRORROGAÇÃO DE CONTRATO</h5>
              <hr />

              <p>
                Devido à greve, será necessária prorrogação da vigência do
                contrato para adequar ao calendário da FURG.
              </p>

              <div className="alert alert-warning" role="alert">
                A nova data de término é dia 30/09/24.
              </div>

              <p>
                Caso não deseje seguir utilizando o serviço, você poderá
                encerrá-lo sem multa rescisória, conforme previsto no contrato.
              </p>

              <p>Selecione uma das opções abaixo para prosseguir:</p>

              <Button variant="danger m-3 p-3" onClick={handleCancel}>
                Quero encerrar meu contrato sem custo
              </Button>

              <Button variant="primary m-3 p-3" onClick={handleAccept}>
                Quero prorrogar meu contrato até setembro
              </Button>
            </div>
          }
        />
      )}
    </>
  );
};

export default Prorrogacao;
