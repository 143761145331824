import { useState, useEffect } from "react";
import { Frame, Loading, Header, Input } from "../components";
import { db, auth, storage } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { ObjetoVazio } from "../components/Formatar";

const Financeiro = () => {
  const [dados, setDados] = useState({});
  const [alterar, setAlterar] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();
  const hoje = moment().format();

  const setData = async () => {
    await setDoc(doc(db, "furg2024", uid), alterar, { merge: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!ObjetoVazio(alterar)) {
      setLoading(true);
      setData().then(() => {
        alert("Enviado com sucesso.");
        window.location.reload(false);
      });
    }
  };

  const uploadFile = async (file, docPath, docUrl) => {
    if (!file) return;

    setLoading(true);

    const path = ref(storage, `furg2024/${uid}/${docPath}`);

    const upload = uploadBytesResumable(path, file, file.type);

    upload.on(
      "state_changed",
      () => {},
      () => {},
      () => {
        getDownloadURL(upload.snapshot.ref).then((url) => {
          setAlterar({ ...alterar, [docUrl]: url, [docPath]: "Enviado" });
          setDados({ ...dados, [docUrl]: url });
          setLoading(false);
        });
      }
    );
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "furg2024", uid));

    const data = qry.data();

    if (data?.status === "a") {
      if (data?.prorrogado) {
        setDados(data);
        setLoading(false);
      } else {
        navigate("../prorrogacao");
      }
    } else {
      navigate("../home");
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      {
        <Frame
          title="Financeiro"
          body={
            <div>
              <table className="table table-sm table-striped table-bordered table-hover my-4">
                <thead>
                  <tr>
                    <th>Mês</th>
                    <th>Valor</th>
                    <th>Recibo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Janeiro 2024</td>
                    <td>
                      {dados?.pagto0124 ||
                        (hoje > "2024-01-09" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto0124 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "0124" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Fevereiro 2024</td>
                    <td>
                      {dados?.pagto0224 ||
                        (hoje > "2024-02-08" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto0224 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "0224" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Março 2024</td>
                    <td>
                      {dados?.pagto0324 ||
                        (hoje > "2024-03-08" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto0324 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "0324" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Abril 2024</td>
                    <td>
                      {dados?.pagto0424 ||
                        (hoje > "2024-04-06" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto0424 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "0424" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Maio 2024</td>
                    <td>
                      {dados?.pagto0524 ||
                        (hoje > "2024-05-09" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto0524 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "0524" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Junho 2024</td>
                    <td>
                      {dados?.pagto0624 ||
                        (hoje > "2024-06-06" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto0624 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "0624" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Julho 2024</td>
                    <td>
                      {dados?.pagto0724 ||
                        (hoje > "2024-07-06" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto0724 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "0724" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Agosto 2024</td>
                    <td>
                      {dados?.pagto0824 ||
                        (hoje > "2024-08-08" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto0824 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "0824" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Setembro 2024</td>
                    <td>
                      {dados?.pagto0924 ||
                        (hoje > "2024-09-07" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto0924 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "0924" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>
                  {/* 
                  <tr>
                    <td>Outubro 2024</td>
                    <td>
                      {dados?.pagto1024 ||
                        (hoje > "2024-10-08" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto1024 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "1024" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Novembro 2024</td>
                    <td>
                      {dados?.pagto1124 ||
                        (hoje > "2024-11-08" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto1124 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "1124" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Dezembro 2024</td>
                    <td>
                      {dados?.pagto1224 ||
                        (hoje > "2024-12-08" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto1224 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "1224" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr> */}
                </tbody>
              </table>

              <div className="justify mb-4">
                <p>
                  <i>
                    Para pagar sua mensalidade, faça um pix para a chave abaixo
                    e encaminhe o comprovante no mês correspondente.
                  </i>
                </p>
              </div>

              <div className="mb-4">
                <h5>Chave Pix CNPJ:</h5>
                <h5> 92.189.612/0001-92</h5>
              </div>

              <form className="mb-4 text-start" onSubmit={handleSubmit}>
                {!dados?.pagto0124 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0124"
                        label="Janeiro 2024"
                        type="file"
                        url={dados?.urlPagto0124}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0124",
                            "urlPagto0124"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}
                {!dados?.pagto0224 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0224"
                        label="Fevereiro 2024"
                        type="file"
                        url={dados?.urlPagto0224}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0224",
                            "urlPagto0224"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}
                {!dados?.pagto0324 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0324"
                        label="Março 2024"
                        type="file"
                        url={dados?.urlPagto0324}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0324",
                            "urlPagto0324"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}
                {!dados?.pagto0424 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0424"
                        label="Abril 2024"
                        type="file"
                        url={dados?.urlPagto0424}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0424",
                            "urlPagto0424"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}
                {!dados?.pagto0524 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0524"
                        label="Maio 2024"
                        type="file"
                        url={dados?.urlPagto0524}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0524",
                            "urlPagto0524"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}
                {!dados?.pagto0624 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0624"
                        label="Junho 2024"
                        type="file"
                        url={dados?.urlPagto0624}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0624",
                            "urlPagto0624"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {!dados?.pagto0724 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0724"
                        label="Julho 2024"
                        type="file"
                        url={dados?.urlPagto0724}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0724",
                            "urlPagto0724"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {!dados?.pagto0824 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0824"
                        label="Agosto 2024"
                        type="file"
                        url={dados?.urlPagto0824}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0824",
                            "urlPagto0824"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {!dados?.pagto0924 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0924"
                        label="Setembro 2024"
                        type="file"
                        url={dados?.urlPagto0924}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0924",
                            "urlPagto0924"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}
                {/* 
                {!dados?.pagto1024 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto1024"
                        label="Outubro 2024"
                        type="file"
                        url={dados?.urlPagto1024}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto1024",
                            "urlPagto1024"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {!dados?.pagto1124 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto1124"
                        label="Novembro 2024"
                        type="file"
                        url={dados?.urlPagto1124}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto1124",
                            "urlPagto1124"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {!dados?.pagto1224 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto1224"
                        label="Dezembro 2024"
                        type="file"
                        url={dados?.urlPagto1224}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto1224",
                            "urlPagto1224"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )} */}

                {!ObjetoVazio(alterar) && (
                  <Row className="mt-2">
                    <Col className="d-grid">
                      <button type="submit" className="btn btn-primary">
                        ENVIAR
                      </button>
                    </Col>
                  </Row>
                )}
              </form>
            </div>
          }
        />
      }
    </>
  );
};

export default Financeiro;
